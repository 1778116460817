import { useState, useEffect } from "react";

import Scene from "../components/Scene.jsx";
import Layout from "../layout/Layout.jsx";

import { Container } from "../styled/Container.js";

// import {getRandomColor} from "../helpers/getRandomColor.js";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function Home(props) {
  const [color, setColor] = useState("purple");

  useEffect(() => {
    setColor(getRandomColor());
  }, []);

  return (
    <Container>
      <Layout color={color} setColor={setColor} getRandomColor={getRandomColor}>
        <Scene color={color} />
      </Layout>
    </Container>
  );
}

export default Home;
