import V from "./radicals/V.jsx";
import O from "./radicals/O.jsx";
import I from "./radicals/I.jsx";
import D from "./radicals/D.jsx";
import Dot from './radicals/Dot.jsx';

function Glyph({color}) {


  // x, y, z
  
  const vCoords = {
    upperLeft: [-1.5, 2.5, 0],
    bottom: [0, -.25, 0],
    upperRight: [1.5, 2.5, 0]
  };

  const vCoordsToBottomOfDots = {
    upperLeft: [-1.05, 1.75, 0],
    bottom: [0, -.25, 0],
    upperRight: [1.05, 1.75, 0]
  };

  const vCoordsLtop = {
    top: [-1.43, 2.5, 0],
    bottom: [-1.22, 2.13, 0],
  };

  const vCoordsRtop = {
    bottom: [1.22, 2.13, 0],
    top: [1.43, 2.5, 0]
  };
 
  const oCoords = {
    center: [0,0],
    radius: 1.5
  };

  const iCoordsTop = {
    top: [0, 3, 0],
    bottom: [0, 2.6, 0]
  };

  const iCoordsMidTop = {
    top: [0, 2.2, 0],
    bottom: [0, .5, 0]
  };

  const iCoordsMidBottom = {
    top: [0, -.25, 0],
    bottom: [0, -2.33, 0]
  };

  const iCoordsBottom = {
    top: [0, -2.72, 0],
    bottom: [0, -3.5, 0]
  };

  const dCoords = {
    upperLeft: [-1.4, .5, 0],
    bottom: [0, -1.5, 0],
    upperRight: [1.4, .5, 0]
  };

  const dotRadius = .2;


  return (
    <>
      <V color={color} vertices={vCoordsToBottomOfDots}/>
      <I color={color} vertices={vCoordsLtop}/>
      <I color={color} vertices={vCoordsRtop}/>
      <Dot color={color} center={[vCoords.upperLeft[0] + .38, vCoords.upperLeft[1] - .56]} radius={dotRadius} />
      <Dot color={color} center={[vCoords.upperRight[0] - .38, vCoords.upperRight[1] - .56]} radius={dotRadius} />
      <O color={color} center={oCoords.center} radius={oCoords.radius}/>
      <I color={color} vertices={iCoordsTop}/>
      <I color={color} vertices={iCoordsMidTop}/>
      <I color={color} vertices={iCoordsMidBottom}/>
      <I color={color} vertices={iCoordsBottom}/>
      <Dot color={color} center={[iCoordsTop.top[0], iCoordsTop.top[1] - .6]} radius={dotRadius} />
      <Dot color={color} center={[iCoordsBottom.bottom[0], iCoordsBottom.bottom[1] + 1]} radius={dotRadius} />
      <D color={color} vertices={dCoords}/>
    </>
  );
}

export default Glyph;
