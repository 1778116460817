import { Canvas } from "@react-three/fiber";
import Glyph from './Glyph.jsx';

import {
  EffectComposer,
  DepthOfField,
  Glitch,
  Noise,
  Scanline
} from "@react-three/postprocessing";

import { GlitchMode, BlendFunction } from 'postprocessing';

import { SceneContainer } from "../styled/Container.js";



function Scene({color}) {

  return (
    <SceneContainer>
      <Canvas orthographic camera={{ zoom: 80 }} dpr={[1, 2]}>
        <ambientLight intensity={0.1} />
        <Glyph color={color}/>
        <EffectComposer>
          <DepthOfField
            focusDistance={0}
            focalLength={0.01}
            bokehScale={5}
            height={480}
          />
          <Glitch
    delay={[3.5, 13.5]} // min and max glitch delay
    duration={[0.3, 0.333]} // min and max glitch duration
    strength={[0.3, 1.0]} // min and max glitch strength
    mode={GlitchMode.SPORADIC} // glitch mode
    active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
    ratio={0.85} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
  />
          <Noise
    premultiply // enables or disables noise premultiplication
    blendFunction={BlendFunction.ADD} // blend mode
          />
          <Scanline
    blendFunction={BlendFunction.OVERLAY} // blend mode
    density={1.25} // scanline density
  />
        </EffectComposer>
      </Canvas>
    </SceneContainer>
  );
}

export default Scene;
