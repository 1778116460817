import { useMemo, useCallback } from 'react';
import * as THREE from 'three';


function Dot({color, center, radius}) {

  let points = [];
  
  for (let i = 0; i <= 360; i++){
    points.push(new THREE.Vector3((Math.sin(i * (Math.PI / 180)) * radius)+center[0], (Math.cos(i * (Math.PI / 180)) * radius)+center[1], 0));
}

  const oPoints = useMemo(() => points, [])
  const onUpdate = useCallback(self => self.setFromPoints(oPoints), [oPoints])

  return (
    <line>
        <bufferGeometry attach="geometry" onUpdate={onUpdate} />
        <lineBasicMaterial attach="material" color={color} linewidth={1} linecap={'round'} linejoin={'round'} />
      </line>
);
};

export default Dot