import { createGlobalStyle } from 'styled-components';

import RenaissanceTTF from './assets/fonts/Renaissance.ttf';

// injectGlobal`
//   @font-face {
//     font-family: 'PTC55F';
//     src: url(${RenaissanceTTF}) format('truetype');
//     font-weight: normal;
//     font-style: normal;
//   }
// `;

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Renaissance';
  src: url(${RenaissanceTTF}) format('truetype');
  font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
  font-family: 'Ilisarniq-Black';
  src: local('NostromoBlack'), url('./src/assets/fonts/Ilisarniq-Black.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Black-Italic';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-BlackItalic.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Bold';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-Bold.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Bold-Italic';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-BoldItalic.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Demi';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-Demi.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Demi-Italic';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-DemiItalic.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Italic';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-Italic.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Light';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-Light.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Light-Italic';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-LightItalic.otf') format('otf');
}

@font-face {
  font-family: 'Ilisarniq-Regular';
  src: local('Ilisarniq'), url('./src/assets/fonts/Ilisarniq-Regular.otf') format('otf');
}
  body {
  margin: 0;
  font-family: 'Ilisarniq-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #420226;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li, p, h3 {
  margin:0;
  padding:0;
  margin-block-start: 0px;
  margin-block-end: 0px;
  }

  a, p {
    // color: rgba(210,212,209,0.56);
    color: #fee2f2;
    text-decoration: none;
  }

  h3 {
    padding-top: .5em;
    padding-bottom: .5em;
  }

  h1 {
    font-size: 4em;
  }

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear 250ms;
}

.fade-exit{
  opacity: 1;
}

.fade-exit.fade-exit-active {
opacity: 0;
transition: opacity 250ms linear;
}

`;

export default GlobalStyle;