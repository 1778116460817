import { useState } from 'react';

import {
  Nav,
  NavBrand,
  NavItems,
  NavItem,
  NavItemButton
} from '../styled/Navbar';


import Modal from '../components/Modal.jsx';

function Navbar({color, setColor, getRandomColor}) {
  const [show, setShow] = useState(false);

  const showModal = () => {
    setColor(getRandomColor());
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

const randomizeColor =() => {
setColor(getRandomColor());
};

  return (
    <Nav>
      <NavBrand color={color} onClick={randomizeColor} to="/">void</NavBrand>
    <NavItems>
        <NavItemButton color={color} onClick={showModal} to="/menu">menu</NavItemButton>
        {/* <NavItemButton color={color} onClick={randomizeColor} to="/">menu</NavItemButton> */}

        {/* <NavItemButton color={color} onClick={showModal}>reservations</NavItemButton>
        <NavItemButton color={color} onClick={showModal}>about</NavItemButton> */}
        {/* <Modal show={show} handleClose={hideModal} size={"notlarge"} color={color}>
          Drinks // Food // Friends
        </Modal> */}
</NavItems>
  </Nav>
);
};

export default Navbar