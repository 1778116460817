// import {useState,useEffect } from "react";

import { MenuItemContainer, Name, Price, Ingredients, Description } from '../styled/Menu.js';

function MenuItem({item}) {
// const [] = useState();
// useEffect(() => {

// },[]);

  return (
    <MenuItemContainer>
      <Name>{item.name}</Name>
      <Price>{item.price}</Price>
      <Ingredients>{item.ingredients}</Ingredients>
      <Description>{item.description}</Description>
    </MenuItemContainer>
);
};

export default MenuItem