import styled, { css } from "styled-components";
import { mediaQueries } from "./mediaQueries.js";

export const Foot = styled.footer`
  max-width: 100vw;
  display: grid;
  flex-grow: 0;
  color: #f5f5f5;
  /* position: absolute;
  bottom: 0px; */
`;

export const FootItems = styled.ul`
  list-style: none;
  padding-top: 1em;
  padding-inline-start: 0;

  ${mediaQueries("phone")} {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  ${mediaQueries("tablet")} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

`;

export const FootItem = styled.li`
  display: flex;
  flex-flow: column nowrap;
  /* flex-grow: 1; */

  justify-content: start;
  align-items: center;
  margin-block-start: 0px;
  padding: 10px;
  font-family: 'Ilisarniq-Light';
  
  
  ${(props) =>
        props.color &&
        css`
            color: ${props.color};
        `} 
  
  margin-bottom: 0.5em;

  ${mediaQueries("phone")} {
    border-top: 1px solid whitesmoke;
    margin-left: 10px;
    margin-right: 10px;
  }  

  ${mediaQueries("tablet")} {
    border-right: 1px solid whitesmoke;
    border-top: none;
    margin-left: 0px;
    margin-right: 0px;

    ${(props) =>
    props.end &&
    css`
      border-right: none;
    `}
  }

  ${(props) =>
    props.col &&
    css`
      grid-column: ${props.col};
    `}

`;

export const FootHeader = styled(FootItem)`
  font-family: 'Ilisarniq-Demi';
  font-size: 1.2em;
  border: none;
  text-shadow: 1px 1px 2px whitesmoke;
  padding-bottom: 3px;
  margin-bottom: 3px;
`;

export const FootBody = styled(FootItem)`
  font-family: 'Ilisarniq-Light';
  font-size: 1em;
  text-shadow: 1px 1px 2px whitesmoke;
  border: none;
`;

