import styled, {css} from "styled-components";
import { Link } from 'react-router-dom';
import { mediaQueries } from "./mediaQueries.js";

export const Nav = styled.nav`
width: 90vw;
  display: flex;
  flex-flow: column;
  flex-grow: 0;
  color: whitesmoke;
  align-items: center;
  justify-content: flex-start;
  padding-top: .5em;
  z-index: 1;
`;


export const NavItems = styled.ul`
top: 0px;
width: 80vw;
  list-style: none;
  padding-inline-start: 0;
  width: 75vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${mediaQueries("phone")} {
    flex-direction: column;
  }

  ${mediaQueries("tablet")} {
    flex-direction: row;
  }
`;

export const NavBar = styled(NavItems)`
flex-flow: row wrap;
justify-content: center;

${mediaQueries("tablet")} {
  width: 70vw;
  flex-flow: row wrap;
  justify-content: space-around;
  }
  
  

`;

export const NavBrand = styled(Link)`
    flex-grow: 0;
    font-family: 'Ilisarniq-Black';
    font-weight: 700;
    color: #whitesmoke;
    text-decoration: none;
    text-shadow: 1px 1px 2px whitesmoke;

    ${(props) =>
        props.color &&
        css`
            color: ${props.color};
        `} 

    ${mediaQueries("phone")} {
      font-size: 5em;
  }  

  ${mediaQueries("laptop")} {
    font-size: 128px;
}  
`;

export const NavItem = styled(Link)`
    margin: 10px 15px;
    /* padding: 10px 0; */
    cursor: pointer;
    transition: 250ms;
    cursor: pointer;
    font-family: 'Ilisarniq-Bold';
    color: whitesmoke;
    text-decoration: none;
    font-size: 1em;

    &:hover {
        transform: scale(1.25);
    }


    ${(props) =>
        props.activeTab &&
        css`
            text-decoration: underline;
        `} 

    ${mediaQueries("phone")} {
        font-size: 2em;
    }  
  
    ${mediaQueries("laptop")} {
      font-size: 3em;
  }  

`;

export const NavItemButton = styled(NavItem)`
    /* padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 2em;
    display: none;
    font-weight: bold;
    height: 10vh; */
    /* border: 2px bold #whitesmoke; */
    text-shadow: 1px 1px 2px whitesmoke;

    ${(props) =>
        props.color &&
        css`
            color: ${props.color};
        `} 

    /* add additional styled if primary*/
    /* ${(props) =>
        props.solid &&
        css`
            background-color: ${props.color} || #663399;
            color: white;
        `} */
`;