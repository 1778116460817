import { useMemo, useCallback } from 'react';
import * as THREE from 'three';


function I({color, vertices}) {
  
  const vPoints = useMemo(() => [new THREE.Vector3(...vertices.top), new THREE.Vector3(...vertices.bottom)], [])
  const onUpdate = useCallback(self => self.setFromPoints(vPoints), [vPoints])

  return (
    <line>
        <bufferGeometry attach="geometry" onUpdate={onUpdate} />
        <lineBasicMaterial attach="material" color={color} linecap={'round'} linejoin={'round'} />
      </line>
);
};

export default I