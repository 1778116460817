import Navbar from "./Navbar.jsx";
import MenuNavbar from "./MenuNavbar.jsx";
import Footer from "./Footer.jsx";

import { Children } from "../styled/Layout";

export default function Layout({ color, children, menu, setColor, getRandomColor }) {
  return (
    <>
      {menu ? <MenuNavbar color={color} setColor={setColor} getRandomColor={getRandomColor}/> : <Navbar color={color} setColor={setColor} getRandomColor={getRandomColor}/>}
      <Children>{children}</Children>
      <Footer color={color}/>
    </>
  );
}
