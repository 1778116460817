export const draftMenu = {
  FOOD: [
    {
      subHeader: "small",
    },
    {
      name: "hand- cut fries",
      price: 5,
      description: "champagne aioli, parmesan, garlic",
      small: true,
    },
    {
      name: "farm lettuces",
      price: 9,
      description: "radish, cucumber, tarragon, smoked balsamic",
      small: true,
    },
    {
      name: "fried ravioli",
      price: 11,
      description: "paprika, ricotta, dead drop vodka sauce",
      small: true,
    },
    {
      name: "burrata",
      price: 13,
      description:
        "greens, pickled beets, dead drop bourbon candied pecans, lemon",
      small: true,
    },
    {
      name: "mussels",
      price: 15,
      description: "shallot, garlic, white wine, fine herbs, toast",
      small: true,
    },
    {
      subHeader: "large",
    },
    {
      name: "burger",
      price: 15,
      description:
        "smash patties, sharp cheddar, sundried tomato aioli, butter lettuce, pickled onion, brioche",
      large: true,
    },
    {
      name: "fettuccine",
      price: 19,
      description:
        "house made pasta, oyster mushroom, crispy shallot, burnt butter, lemon",
      large: true,
    },
    {
      name: "half chicken",
      price: 21,
      description: "parsnips, english potato, demi glace, fine herbs",
      large: true,
    },
    {
      name: "fish",
      price: 25,
      description:
        "Rushing Waters trout, fennel, apple, ancient grains, petit salad",
      large: true,
    },
    {
      name: "steak ‘frites’",
      price: 27,
      description:
        "brussel sprouts, butternut squash, carrots, house fries, chimichurri aioli",
      large: true,
    },
    {
      subHeader: "sweet",
    },
    {
      name: "cheesecake",
      price: 11,
      description: "berries, sugar, salt",
      sweet: true,
    },
    {
      name: "cookie",
      price: 7,
      description: "house ice cream",
      sweet: true,
    },
  ],
  COCKTAILS: [
    {
      subHeader: "familiar",
    },
    {
      name: "old fashioned",
      price: 12,
      ingredients: "whiskey // demerara // angostura",
      description: "comes on cool ice",
      familiar: true,
    },
    {
      name: "manhattan",
      price: 11,
      ingredients: "whiskey // vermouth // bitters",
      description: "like the one in new york",
      familiar: true,
    },
    {
      name: "martini",
      price: 11,
      ingredients: "gin or vodka// classic or dirty // twist or olive",
      description: "these are the questions",
      familiar: true,
    },
    {
      name: "moscow mule",
      price: 12,
      ingredients: "vodka // lime // ginger // polaroid",
      description: "pose - pose - pose - photoshoot",
      familiar: true,
    },
    {
      name: "corpse reviver #2",
      price: 11,
      ingredients: "gin // curacao // lemon // absinthe",
      description: "delicious necromancy",
      familiar: true,
    },
    {
      subHeader: "unfamiliar",
    },
    {
      name: "cosmo kramer",
      price: 11,
      ingredients: "vodka // curacao // lime // soda // cranberry",
      description: "the classic, served long and kooky",
      unfamiliar: true,
    },
    {
      name: "thrown mai tai",
      price: 11,
      ingredients: "aged rum // curacao // lime // orgeat",
      description: "a regular garden variety mai tai, thrown",
      unfamiliar: true,
    },
    {
      name: "rangoon fizz",
      price: 11,
      ingredients: "gin // ginger // lime // mint // tonic",
      description: "darn fine, nice and easy",
      unfamiliar: true,
    },
    {
      name: "blood and sand",
      price: 11,
      ingredients: "whiskey // orange // vermouth // cherry",
      description: "no actual blood or sand involved",
      unfamiliar: true,
    },
    {
      name: "saturn",
      price: 11,
      ingredients: "gin // lemon // passionfruit // falernum // orgeat",
      description: "the whole dang planet",
      unfamiliar: true,
    },
    {
      name: "the economy",
      price: 11,
      ingredients: "gin // persimmon // vermouth ",
      description: "a negroni by any other name",
      unfamiliar: true,
    },
    {
      name: "remember the maine",
      price: 11,
      ingredients: "pecan whiskey // absinthe // vermouth // aronia",
      description: "somewhere south of manhattan",
      unfamiliar: true,
    },
    {
      name: "nux squared",
      price: 11,
      ingredients: "rye  // pecan // vermouth // bitters",
      description: "it’s hip to be square",
      unfamiliar: true,
    },
  ],

  BEER: [
    {
      subHeader: "draft",
    },
    {
      name: "3 floyds gumballhead",
      price: 6,
      ingredients: "ABV 5.6% /// IBU 35 /// indiana",
      description: "dry hopped american wheat ale",
    },
    {
      name: "warpigs lazurite IPA",
      price: 6,
      ingredients: "ABV 7.5% /// IBU 70 /// indiana",
      description: "citrusy full-bodied American IPA",
    },
    {
      name: "thornton good old fashioned lager",
      price: 6,
      ingredients: "ABV 4.3% /// IBU 11 /// indiana",
      description: "from our friends at Evil Horse Brewing",
    },
    {
      subHeader: "cans & bottles",
    },
    {
      name: "warpigs salmon pants",
      price: 6,
      ingredients: "ABV 5.3% /// IBU 60 /// Indiana" ,
      description: "hoppy lager with citrus, melon, grape",
    },
    {
      name: "dovetail hefeweizen",
      price: 6,
      info: "ABV 4.8% /// IBU 16 /// illinois" ,
      description: "orange wheat beer, spice and fruit",
    },
    {
      name: "right bee blossom cider",
      price: 6,
      info: "ABV 6% /// IBU 0 /// chicago",
      description: "semi-dry with cherry blossom and myrtle",
    },
  ],

  SPIRITS: [
    // {
    //   subHeader: "vodka",
    // },
    // {
    //   subHeader: "gin",
    // },
    // {
    //   subHeader: "rum",
    // },
    // {
    //   subHeader: "whiskey",
    // },
    // {
    //   subHeader: "",
    // },
    {
      name: "dead drop vodka",
      price: 6,
      description: "distilled from 100% American grain",
    },
    {
      name: "dead drop pecan whiskey",
      price: 6,
      description: "no artificial anything",
    },
    {
      name: "dead drop gin",
      price: 6,
      description: "a unique blend of 12 botanicals",
    },
    {
      name: "dead drop dark rum",
      price: 6,
      description: "honest as it is delicious",
    },
    {
      name: "dead drop bourbon whiskey",
      price: 6,
      description: "aged for a minimum of two years",
    },
  ],

  NABEV: [
    {
      name: "soft drinks",
      price: 5,
      description: "coke // diet // sprite // ginger ale // tonic",
    },
    {
      name: "essensed sodas",
      price: 5,
      description: "thai herbs // malted rye-walnut // lemon rosemary",
    },
  ],
};
