import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  overflow-y: visible;
  /* border: 1px solid hotpink; */
  
`;

export const SceneContainer = styled(Container)`
width: 90vw; 
 height: 75vh;
 `;

export const MenuContainer = styled(Container)`
 width: 98vw; 
 height: 98vh;

 /* border: 1px solid hotpink; */
 `;

export const MenuTabContainer = styled(Container)`
/* border: 1px solid hotpink; */
min-height: 0;
flex-grow: 1;
width: 98vw;
`;
