import { Route, Switch } from "react-router-dom";

import Home from './screens/Home.jsx';
import Menu from './screens/Menu.jsx';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      {/* <Route path="/menu" component={Home} /> */}
      <Route path="/menu" component={Menu} />
    </Switch>
  );
}

export default App;
